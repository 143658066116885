import React, { useRef, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { connect as connectFela } from "react-fela";
import { useSpring, animated, config } from "react-spring";

import ArticleCard from "components/ArticleCard";
import NextButton from "components/NextButton";
import PrevButton from "components/PrevButton";
import UnderlinedTitle from "components/UnderlinedTitle";
import colors from "theme/colors";
import fluidDimensions from "utilities/fluidDimensions";
import fluidValues from "utilities/fluidValues";

const Ideas = ({ rules, styles, currentArticleSlug, country }) => {
  const {
    allSanityArticle: { edges: nodes },
  } = useStaticQuery(graphql`
    query {
      allSanityArticle(
        sort: { fields: published, order: DESC }
        filter: { articleType: { nin: ["Video"] } }
      ) {
        edges {
          node {
            countrySet
            title
            cardtitle
            articleType
            published
            excerpt
            cardintro
            _rawIntro
            slug {
              current
            }
            author {
              name
            }
          }
        }
      }
    }
  `);
  const [current, setCurrent] = useState(0);
  const scrollContainer = useRef();
  const [x, setX] = useSpring(() => ({
    immediate: false,
    x: 0,
    config: config.slow,
  }));

  const slideBack = () => {
    const el = scrollContainer.current;
    let len = null;
    if (el.scrollLeft < el.clientWidth) {
      len = 0;
    } else {
      len = el.scrollLeft - el.clientWidth;
    }

    setX({ x: len });
    if (el && el.scrollLeft > 0) {
      setCurrent(0);
    }
  };

  const slide = () => {
    const el = scrollContainer.current;
    let len = null;

    if (el.scrollWidth - el.scrollLeft <= 2 * el.clientWidth) {
      len = el.scrollLeft + (el.scrollWidth - el.clientWidth - el.scrollLeft);
    } else {
      len = el.scrollLeft + el.clientWidth;
    }

    setX({ x: len });
    if (len + el.clientWidth === el.scrollWidth) {
      setCurrent(2);
    } else {
      setCurrent(1);
    }
  };
  return (
    <>
      <div className={styles.title}>
        <UnderlinedTitle tag={"h2"} extend={{ styles: rules.overrideTitle }}>
          More Ideas
        </UnderlinedTitle>
      </div>
      <div className={styles.circle}></div>
      <div className={styles.hideScrollbars}>
        {nodes.length > 0 && (
          <animated.div
            ref={scrollContainer}
            className={styles.articlesContainer}
            scrollLeft={x.x}
          >
            {nodes
              .filter(({ node }) => node.countrySet === country && node.slug.current !== currentArticleSlug.current)
              .slice(0, 5)
              .map(({ node: article }, index) => {
                return (
                  <ArticleCard
                    extend={{ styles: rules.article, wrap: rules.articleWrap }}
                    key={index}
                    title={article.title}
                    author={article.author}
                    published={article.published}
                    cardIntro={article.cardintro}
                    articleIntro={article._rawIntro}
                    articleType={article.articleType}
                    excerpt={article.excerpt}
                    cardTitle={article.cardtitle}
                    slug={{ current: "/our-thoughts/" + article.slug.current }}
                  />
                );
              })}
          </animated.div>
        )}
      </div>
      <div className={styles.buttonContainer}>
        {current > 0 && (
          <PrevButton
            onClick={slideBack}
            extend={{ styles: rules.prevButton }}
          />
        )}
        {current < 2 && (
          <NextButton onClick={slide} extend={{ styles: rules.nextButton }} />
        )}
      </div>
    </>
  );
};

/*
 */
const styles = (props) => ({
  hideScrollbars: {
    overflow: "hidden",
  },
  articlesContainer: {
    display: "flex",
    ...fluidValues({ marginLeft: [0, 111] }),
    overflow: "hidden",
    overflowX: "scroll",
    height: "100%",
    marginBottom: -20,
    paddingBottom: 20,
  },
  article: {
    "&:not(:first-child)": {
      ...fluidValues({ marginLeft: [24, 61] }),
    },
    flex: "0 0 auto",
    zIndex: 1,
  },
  articleWrap: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  title: {
    ...fluidValues({ marginTop: [0, 120], marginBottom: [46, 90] }),
    position: "relative",
    zIndex: 1,
  },
  overrideTitle: {
    marginBottom: "0 !important",
  },
  circle: {
    backgroundColor: colors.colorCanvas200,
    borderRadius: "50%",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 0,
    ...fluidValues({ marginLeft: [-53, -70] }),
    ...fluidDimensions({ width: [330, 831], height: [330, 831] }),
  },
  buttonContainer: {
    display: "flex",
    ...fluidValues({ marginTop: [30, 90] }),

    phoneOnly: {
      display: "none",
    },
  },
  prevButton: {
    zIndex: 2,
    ...fluidValues({ marginRight: [12, 24] }),
  },
  nextButton: {
    zIndex: 2,
  },
});

export default connectFela(styles)(Ideas);
